import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

const SliderBrands = new Swiper('#slider-brands', {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 0,
  observer: true,
  observeParents: true,
  rewind: true,

  navigation: {
    nextEl: '#slider-brands .swiper-button-next',
    prevEl: '#slider-brands .swiper-button-prev',
  },
})
